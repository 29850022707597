@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

.faq-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.faq-image img {
    width: 488px;
    margin-right: 20px;
    position:relative;
}

.faq-content {
    width: 810px;
}

.faq-item {
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 4px;
    padding: 8px;
    padding-left: 20px;
    font-family: 'Raleway', sans-serif;
    color: #0fa487;
    font-size: 26px;
    margin-bottom: 20px;
    text-align: left;
}

.faq-question {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.arrow {
    margin-left: 10px;
    transition: transform 0.3s;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    padding: 0 20px;
    color: black;
}

.faq-item.active .faq-answer {
    max-height: 200px; 
    padding: 10px 20px;
}

.faq-item.active .arrow {
    transform: rotate(180deg);
}

.faq-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.faq-head {
    font-family: 'Raleway', sans-serif;
    color: #0fa487;
    font-size: 82px;
    margin-top: 0;
}

h1 {
    margin-top: 0;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .faq-content {
        width: 100%;
        padding: 0 20px;
    }

    .faq-item {
        font-size: 20px;
    }

    .faq-head {
        font-size: 48px;
        text-align: center;
    }

    .faq-box {
        flex-direction: column;
    }

    .faq-image {
        display: none;
    }
}
